<template>
  <div
    :key="genKey"
    class="PaapFormNTD LAccordionParent LAccordionRegular"
    :class="{ allReadonly: readonly }"
  >
    <div
      id="Section_7"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[7] ? 'LActive' : ''"
    >
      <div @click="select(7)" class="LAccordionItemHead">
        <span> <icon :valide="true" /> Informații Referat </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC8']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC8" class="NoTableMargin">
          <div class="form-group">
            <b>Denumire referat</b>
            <input
              type="text"
              class="vInput form-disabled"
              :value="reference.name"
              disabled=""
            />
          </div>
          <div class="form-flex">
            <div class="form-group">
              <b>Instituție</b>
              <input
                type="text"
                class="vInput form-disabled"
                :value="Data.referenceInfo.institution"
                disabled=""
              />
            </div>
            <div class="form-group">
              <b>Structură organizatorică</b>
              <input
                type="text"
                class="vInput form-disabled"
                :value="Data.referenceInfo.department"
                disabled=""
              />
            </div>
          </div>
          <div class="form-flex">
            <div class="form-group">
              <b>Utilizator</b>
              <input
                type="text"
                class="vInput form-disabled"
                :value="Data.referenceInfo.user"
                disabled=""
              />
            </div>
            <div class="form-group">
              <b>Data și ora</b>
              <input
                type="text"
                class="vInput form-disabled"
                :value="Data.referenceInfo.date"
                disabled=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="Section_0"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[0] ? 'LActive' : ''"
    >
      <div @click="select(0)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[0]" /> {{ NAMES[0].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[0].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC1']">
        <div ref="AC1" class="NoTableMargin">
          <div>
            <h4>
              {{ NAMES[1].short }}
              <i
                class="fas fa-info-circle"
                style="font-size: 0.8em"
                v-tooltip="{ offset: 4, content: NAMES[1].full }"
              ></i>
            </h4>
            <table-component :Data="Data.achizitionDesc.table" />
          </div>
          <div :class="{ allReadonly: !isOwner }">
            <h4>
              {{ NAMES[2].short }}
              <i
                class="fas fa-info-circle"
                style="font-size: 0.8em"
                v-tooltip="{ offset: 4, content: NAMES[2].full }"
              ></i>
            </h4>
            <label for="ACHKDHKJQNSZMDN" class="vCheckbox">
              <input
                id="ACHKDHKJQNSZMDN"
                type="radio"
                name="AchizitionDestinationRadiobox"
                v-model="Data.achizitionDesc.destination"
                value="one"
              />
              Realizarea unui interes public
            </label>
            <br />
            <label for="ACOOEQQJQNSZMDN" class="vCheckbox">
              <input
                id="ACOOEQQJQNSZMDN"
                type="radio"
                name="AchizitionDestinationRadiobox"
                v-model="Data.achizitionDesc.destination"
                value="two"
              />
              Asigurarea funcţionării autorităţii contractante în vederea
              îndeplinirii misiunii/obiectivelor pentru care aceasta a fost
              înfiinţată
            </label>
          </div>
          <div :class="{ allReadonly: !isOwner }">
            <h4>Tipul contractului</h4>
            <label for="ACHKDHKJQNOZMDN" class="vCheckbox">
              <input
                id="ACHKDHKJQNOZMDN"
                type="radio"
                name="AchizitionTypeRadiobox"
                v-model="Data.achizitionDesc.type"
                value="one"
              />
              Contract
            </label>
            <br />
            <label for="ACHKDHKJQNOZMDA" class="vCheckbox">
              <input
                id="ACHKDHKJQNOZMDA"
                type="radio"
                name="AchizitionTypeRadiobox"
                v-model="Data.achizitionDesc.type"
                value="three"
              />
              Factură
            </label>
            <br />
            <label for="ACOOEQQJQQSZMDN" class="vCheckbox">
              <input
                id="ACOOEQQJQQSZMDN"
                type="radio"
                name="AchizitionTypeRadiobox"
                v-model="Data.achizitionDesc.type"
                value="two"
              />
              Acord-Cadru
            </label>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="(isOwner && Data.modalitateDeCentralizare) || !isOwner"
      id="Section_13"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[13] ? 'LActive' : ''"
    >
      <div @click="select(13)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[13]" /> {{ NAMES[31].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[31].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC14']">
        <div ref="AC14">
          <div style="display: flex;">
            <div style="flex: 0.5">
              <label>Tip</label>
              <v-select
                class="mod-centralizare-select"
                v-model="Data.modalitateDeCentralizareType"
                :options="modalitateDeCentralizareTypes"
                style=" margin-right: 10px;"
                :searchable="false"
                :disabled="!canSetModalitateDeCentralizare"
                :max-height="100"
              ></v-select>
            </div>
            <div style="flex: 1">
              <label>Cod CPV</label>
              <v-select
                class="mod-centralizare-select"
                v-model="optiuneCentralizare"
                :options="optiuniCentralizare"
                :searchable="false"
                :disabled="!canSetModalitateDeCentralizare"
                :max-height="100"
              >
                <template #option="item">
                  <span class="v-select_li_custom">
                    <span>{{ item.key + " - " + item.label }}</span>
                    <i v-tooltip="item['label']" class="fas fa-info-circle"></i>
                  </span>
                </template>

                <template #selected-option="item">
                  <span>{{ item.key + " - " + item.label }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="(isOwner && Data.budgetClassification.length > 1) || !isOwner"
      id="Section_14"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[14] ? 'LActive' : ''"
    >
      <div @click="select(14)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[14]" /> {{ NAMES[32].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[32].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC15']">
        <div ref="AC15">
          <div>
            <label>Funcțională</label>
            <v-select-infinite
              class="mod-centralizare-select"
              v-model="budgetClassificationFunctional"
              label="titlu,name"
              :overflowPrevent="true"
              :appendToBody="true"
              :apiModule="bugetareGet"
              :apiOptions="{ prepareSearch: prepareSearchFunctional }"
              :disabled="!canSetBudgetClassification"
              :startDelay="500"
            >
            </v-select-infinite>
          </div>
          <div style="margin-top: 15px;">
            <label>Economică</label>
            <v-select-infinite
              class="mod-centralizare-select"
              v-model="budgetClassificationEconomic"
              label="titlu,name"
              :overflowPrevent="true"
              :appendToBody="true"
              :apiModule="bugetareGet"
              :apiOptions="{ prepareSearch: prepareSearchEconomic }"
              :disabled="!canSetBudgetClassification"
              :startDelay="500"
            >
            </v-select-infinite>
          </div>
        </div>
      </div>
    </div>

    <div
      id="Section_1"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[1] ? 'LActive' : ''"
    >
      <div @click="select(1)" class="LAccordionItemHead">
        <span> <icon :valide="valide[1]" /> Fundamentarea necesităţii </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC2']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC2" class="">
          <div>
            <div class="form-group">
              <editor v-model="Data.fundamentNeed" minHeight="150" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="Section_2"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[2] ? 'LActive' : ''"
    >
      <div @click="select(2)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[2]" /> {{ NAMES[4].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[4].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC3']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC3" class="">
          <div>
            <h4>Necesitatea identificată</h4>
            <div class="form-group">
              <editor v-model="Data.identifiedNeed" minHeight="150" />
            </div>
          </div>
          <div>
            <h6>
              {{ NAMES[5].short }}
              <i
                class="fas fa-info-circle"
                style="font-size: 0.8em"
                v-tooltip="{ offset: 4, content: NAMES[5].full }"
              ></i>
            </h6>
            <Inputs
              v-model="Data.obectives1.ob1"
              label="institutionObjective.name"
              :selectMode="true"
              :apiModule="multiSelectApiModule"
            />
          </div>
          <div>
            <h6>
              {{ NAMES[6].short }}
              <i
                class="fas fa-info-circle"
                style="font-size: 0.8em"
                v-tooltip="{ offset: 4, content: NAMES[6].full }"
              ></i>
            </h6>
            <Inputs v-model="Data.obectives1.ob2" />
          </div>
          <div>
            <h6>
              {{ NAMES[7].short }}
              <i
                class="fas fa-info-circle"
                style="font-size: 0.8em"
                v-tooltip="{ offset: 4, content: NAMES[7].full }"
              ></i>
            </h6>
            <Inputs v-model="Data.obectives1.ob3" />
          </div>
          <div>
            <h6>
              {{ NAMES[8].short }}
              <i
                class="fas fa-info-circle"
                style="font-size: 0.8em"
                v-tooltip="{ offset: 4, content: NAMES[8].full }"
              ></i>
            </h6>
            <Inputs v-model="Data.obectives1.ob4" />
          </div>
        </div>
      </div>
    </div>
    <div
      id="Section_3"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[3] ? 'LActive' : ''"
    >
      <div @click="select(3)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[3]" /> {{ NAMES[9].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[9].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC4']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC4" class="">
          <div>
            <div class="form-group">
              <editor v-model="Data.justificationChoice" minHeight="150" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="Section_4"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[4] ? 'LActive' : ''"
    >
      <div @click="select(4)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[4]" />{{ NAMES[10].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[10].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC5']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC5">
          <div>
            <h4>
              {{ NAMES[12].short }}
              <i
                class="fas fa-info-circle"
                style="font-size: 0.8em"
                v-tooltip="{ offset: 4, content: NAMES[12].full }"
              ></i>
            </h4>
            <div style="overflow: auto">
              <table class="form-table" style="color: #475467">
                <tr>
                  <th>Cod CPV</th>
                  <th>Categorie achiziție</th>
                  <th>Cantitate</th>
                  <th>Unitate de măsură</th>
                </tr>
                <template v-for="(e, i) in Data.legislationContext.cpvCodes">
                  <tr :key="i">
                    <td colspan="4">
                      Nevoie{{ e._dominant ? " dominantă" : "" }}: {{ e._need }}
                    </td>
                  </tr>
                  <tr
                    :key="i + 0.5"
                    :class="{ boldAllFormInputs: e._dominant }"
                  >
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e.cpvCode"
                          disabled=""
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e._label"
                          disabled=""
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e.qty"
                          disabled=""
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e.mu"
                          disabled=""
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="Section_5"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[5] ? 'LActive' : ''"
    >
      <div @click="select(5)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[5]" /> {{ NAMES[11].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[11].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC6']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC6" class="">
          <div>
            <div class="form-group">
              <b>Categorie achiziție</b>
              <div class="form-group">
                <input
                  type="text"
                  class="vInput form-disabled"
                  :value="Data.achizitionCategory[0] || 'itemilor'"
                  disabled=""
                />
              </div>
            </div>
            <div>
              <h5>Cantitatea şi unitatea de măsură corespunzătoare</h5>
              <table class="form-table" style="color: #475467">
                <tr>
                  <th>Cantitate</th>
                  <th>Unitate de măsură</th>
                  <th>Valoarea unitară, fără TVA</th>
                  <th>Valoarea unitară, cu TVA</th>
                  <th>Valoarea estimată totală, fără TVA</th>
                  <th>Valoarea estimată totală, cu TVA</th>
                </tr>
                <template v-for="(e, i) in Data.priceUnits">
                  <tr :key="i">
                    <td colspan="4">
                      Nevoie{{ e._dominant ? " dominantă" : "" }}: {{ e[6] }}
                    </td>
                  </tr>
                  <tr
                    :key="i + 0.5"
                    :class="{ boldAllFormInputs: e._dominant }"
                  >
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e[0]"
                          disabled=""
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e[1]"
                          disabled=""
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e[2]"
                          disabled=""
                        />
                      </div>
                    </td>

                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e[4]"
                          disabled=""
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e[3]"
                          disabled=""
                        />
                      </div>
                    </td>

                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="vInput form-disabled"
                          style="font-weight: inherit"
                          :value="e[5]"
                          disabled=""
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </table>

              <p align="right">Total, fără TVA: {{ totalPriceWithoutTva }}</p>
              <p align="right">Total, cu TVA: {{ totalPrice }}</p>
            </div>
            <div>
              <h5>
                {{ NAMES[14].short }}
                <i
                  class="fas fa-info-circle"
                  style="font-size: 0.8em"
                  v-tooltip="{ offset: 4, content: NAMES[14].full }"
                ></i>
              </h5>
              <div>
                <div class="TBParent">
                  <table>
                    <thead>
                      <th>Denumire</th>
                      <th>Acțiuni</th>
                    </thead>
                    <tbody>
                      <tr v-if="!Data.annexes.length">
                        <td colspan="2">
                          <h4 class="prettyH4" style="color: #888">
                            <i class="fas fa-times"></i>
                            Informația lipsește
                          </h4>
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(file, i) in Data.annexes"
                        :key="i"
                        :style="file._dominant ? 'font-weight: bolder;' : ''"
                      >
                        <td>{{ file[0].name }}</td>
                        <td>
                          <a
                            href="#"
                            @click.prevent="$emit('getNeedFile', file[0])"
                            ><i class="fas fa-eye"></i> Deschide</a
                          >
                          |
                          <a
                            href="#"
                            @click.prevent="$emit('downloadFile', file)"
                            :style="
                              file[1] ? 'pointer-events: none;color: #666;' : ''
                            "
                            ><i class="fas fa-download"></i> Descarcă</a
                          >
                          <span
                            v-if="file[1]"
                            style="
                              display: inline-block;
                              transform: translateY(2px);
                            "
                          >
                            <i
                              class="fas fa-sync fa-spin"
                              style="
                                font-size: 2rem;
                                pointer-events: none;
                                margin-left: 0.5rem;
                              "
                            ></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h6>Descriere {{ Data.achizitionCategory[2] || "itemi" }}</h6>
                <pre
                  class="form-disabled html-prev"
                  style="max-height: 30rem"
                  v-html="Data.docSuports.desc"
                ></pre>
              </div>
              <div>
                <h6>
                  <!-- {{
                  {1:'Specificații tehnice',2:'Cerințe funcționale ale produsului / descriere servicii',3:'Descriere lucrări'}[Data.cpvType] || '-'
                  }} -->
                  Descrierea rezultatului urmărit la finalul contractului
                </h6>
                <Inputs v-model="Data.docSuports.requirements" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="Section_6"
      style="margin-top: 1.5rem"
      class="LAccordionItem"
      :class="selected[6] ? 'LActive' : ''"
    >
      <div @click="select(6)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[6]" /> {{ NAMES[15].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[15].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC7']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC7">
          <div class="mx-full_width form-list-2">
            <div class="form-group">
              <b>
                <template v-if="singleCalendarInput">
                  Data estimată a achiziției
                </template>
                <template v-else>
                  {{ calendarNr[0] }} {{ NAMES[16].short }}
                  <i
                    class="fas fa-info-circle"
                    style="font-size: 0.8em"
                    v-tooltip="{ offset: 4, content: NAMES[16].full }"
                  ></i>
                </template>
              </b>
              <div>
                <date-picker
                  v-model="Data.calendar.signContract"
                  format="DD-MM-YYYY"
                  valueType="YYYY-MM-DDTHH:mm:ssZ"
                  :confirm="true"
                  :disabled-date="(x) => dateSelectableManager(x, 0)"
                  :default-value="getDefaultDate()"
                />
              </div>
            </div>
            <template v-if="!singleCalendarInput">
              <div v-if="showSignFirstContract" class="form-group">
                <b
                  >{{ calendarNr[1] }} {{ NAMES[17].short }}
                  <i
                    class="fas fa-info-circle"
                    style="font-size: 0.8em"
                    v-tooltip="{ offset: 4, content: NAMES[17].full }"
                  ></i
                ></b>
                <div>
                  <date-picker
                    v-model="Data.calendar.signFirstContract"
                    format="DD-MM-YYYY"
                    valueType="YYYY-MM-DDTHH:mm:ssZ"
                    :confirm="true"
                    :disabled-date="(x) => dateSelectableManager(x, 1)"
                    :default-value="getDefaultDate()"
                  />
                </div>
              </div>
              <div class="form-group">
                <b
                  >{{ calendarNr[2] }} {{ NAMES[18].short }}
                  <i
                    class="fas fa-info-circle"
                    style="font-size: 0.8em"
                    v-tooltip="{ offset: 4, content: NAMES[18].full }"
                  ></i
                ></b>
                <div>
                  <date-picker
                    v-model="Data.calendar.release"
                    format="DD-MM-YYYY"
                    valueType="YYYY-MM-DDTHH:mm:ssZ"
                    :confirm="true"
                    :disabled-date="(x) => dateSelectableManager(x, 2)"
                    :default-value="getDefaultDate()"
                  />
                </div>
              </div>
              <div class="form-group">
                <b
                  >{{ calendarNr[3] }} {{ NAMES[19].short }}
                  <i
                    class="fas fa-info-circle"
                    style="font-size: 0.8em"
                    v-tooltip="{ offset: 4, content: NAMES[19].full }"
                  ></i
                ></b>
                <div>
                  <date-picker
                    v-model="Data.calendar.fulfillmentOfObligations"
                    format="DD-MM-YYYY"
                    valueType="YYYY-MM-DDTHH:mm:ssZ"
                    :confirm="true"
                    :disabled-date="(x) => dateSelectableManager(x, 3)"
                    :default-value="getDefaultDate()"
                  />
                </div>
              </div>
              <div class="form-group">
                <b
                  >{{ calendarNr[4] }} {{ NAMES[20].short }}
                  <i
                    class="fas fa-info-circle"
                    style="font-size: 0.8em"
                    v-tooltip="{ offset: 4, content: NAMES[20].full }"
                  ></i
                ></b>
                <div>
                  <date-picker
                    v-model="Data.calendar.finishContract"
                    format="DD-MM-YYYY"
                    valueType="YYYY-MM-DDTHH:mm:ssZ"
                    :confirm="true"
                    :disabled-date="(x) => dateSelectableManager(x, 4)"
                    :default-value="getDefaultDate()"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      id="Section_8"
      style="margin-top: 1.5rem"
      v-if="actives['S8']"
      class="LAccordionItem"
      :class="selected[8] ? 'LActive' : ''"
    >
      <div @click="select(8)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[8]" /> {{ NAMES[21].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[21].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC9']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC9">
          <table class="form-table" style="color: #475467">
            <tr>
              <th>
                {{ NAMES[22].short }}
                <i
                  class="fas fa-info-circle"
                  style="font-size: 0.8em"
                  v-tooltip="{ offset: 4, content: NAMES[22].full }"
                ></i>
              </th>
              <th>
                {{ NAMES[23].short }}
                <i
                  class="fas fa-info-circle"
                  style="font-size: 0.8em"
                  v-tooltip="{ offset: 4, content: NAMES[23].full }"
                ></i>
              </th>
            </tr>
            <tr>
              <td style="padding-top: 0">
                <inp-number v-model="Data.perspectives.fonds" />
              </td>
              <td style="padding-top: 0">
                <v-select
                  :value="Data.perspectives.sources"
                  :appendToBody="true"
                  :clearable="false"
                  :no-drop="true"
                  :disabled="true"
                  :maxHeight="100"
                  label="sourceName"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div
      id="Section_11"
      style="margin-top: 1.5rem"
      v-if="actives['S11']"
      class="LAccordionItem"
      :class="selected[11] ? 'LActive' : ''"
    >
      <div @click="select(11)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[11]" /> {{ NAMES[24].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[24].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC12']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC12">
          <h4 v-if="!Data.s10.length" class="prettyH4" style="color: #888">
            <i class="fas fa-times"></i>
            Informația lipsește
          </h4>
          <div v-else v-for="(e, i) in Data.s10" :key="i">
            <h5>Nevoie{{ e._dominant ? " dominantă" : "" }}: {{ e.name }}</h5>
            <div
              class="form-group"
              :class="e.mod ? '' : 'alert-danger'"
              :style="
                e.mod
                  ? ''
                  : 'background: transparent !important;font-weight:bolder;'
              "
            >
              <b
                >{{ NAMES[25].short }}
                <i
                  class="fas fa-info-circle"
                  style="font-size: 0.8em"
                  v-tooltip="{ offset: 4, content: NAMES[25].full }"
                ></i
              ></b>
              <input
                type="text"
                class="vInput form-disabled"
                disabled="disabled"
                :value="e.mod || '(Lipsește)'"
              />
            </div>
            <div v-if="e.notes.length" class="form-group">
              <b
                >{{ NAMES[26].short }}
                <i
                  class="fas fa-info-circle"
                  style="font-size: 0.8em"
                  v-tooltip="{ offset: 4, content: NAMES[26].full }"
                ></i
              ></b>
              <div v-if="e.notes" class="solidCheckboxes" style="color: #555">
                <div>
                  <label for="PaapBox_1"
                    ><input
                      type="checkbox"
                      id="PaapBox_1"
                      disabled=""
                      :checked="e.notes.includes('SR EN ISO')"
                    />
                    SR EN ISO</label
                  >
                </div>
                <div>
                  <label for="PaapBox_2"
                    ><input
                      type="checkbox"
                      id="PaapBox_2"
                      disabled=""
                      :checked="
                        e.notes.includes(
                          'Evaluări tehnice europene [acolo unde este aplicabil]'
                        )
                      "/>
                    {{ NAMES[27].short }}
                    <i
                      class="fas fa-info-circle"
                      style="font-size: 0.8em"
                      v-tooltip="{ offset: 4, content: NAMES[27].full }"
                    ></i
                  ></label>
                </div>
                <div>
                  <label for="PaapBox_3"
                    ><input
                      type="checkbox"
                      id="PaapBox_3"
                      disabled=""
                      :checked="
                        e.notes.includes(
                          'Specificaţii tehnice comune [acolo unde este aplicabil]'
                        )
                      "/>
                    {{ NAMES[28].short }}
                    <i
                      class="fas fa-info-circle"
                      style="font-size: 0.8em"
                      v-tooltip="{ offset: 4, content: NAMES[28].full }"
                    ></i
                  ></label>
                </div>
                <div>
                  <label for="PaapBox_4"
                    ><input
                      type="checkbox"
                      id="PaapBox_4"
                      disabled=""
                      :checked="e.notes.includes('Standarde internaţionale')"
                    />
                    Standarde internaţionale</label
                  >
                </div>
                <div>
                  <label for="PaapBox_5"
                    ><input
                      type="checkbox"
                      id="PaapBox_5"
                      disabled=""
                      :checked="
                        e.notes.includes(
                          'Sisteme de referinţă tehnice instituite de organisme de standardizare europene [acolo unde este aplicabil]'
                        )
                      "/>
                    {{ NAMES[29].short }}
                    <i
                      class="fas fa-info-circle"
                      style="font-size: 0.8em"
                      v-tooltip="{ offset: 4, content: NAMES[29].full }"
                    ></i
                  ></label>
                </div>
                <div>
                  <label for="PaapBox_6"
                    ><input
                      type="checkbox"
                      id="PaapBox_6"
                      disabled=""
                      :checked="
                        e.notes.includes(
                          'Alte norme la nivel naţional care reglementează aspecte tehnice/specificaţii tehnice naţionale'
                        )
                      "
                    />
                    Alte norme la nivel naţional care reglementează aspecte
                    tehnice/specificaţii tehnice naţionale</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="Section_10"
      style="margin-top: 1.5rem"
      v-if="actives['S10']"
      class="LAccordionItem"
      :class="selected[10] ? 'LActive' : ''"
    >
      <div @click="select(10)" class="LAccordionItemHead">
        <span>
          <icon :valide="valide[10]" /> {{ NAMES[30].short }}
          <i
            class="fas fa-info-circle"
            style="font-size: 0.8em"
            v-tooltip="{ offset: 4, content: NAMES[30].full }"
          ></i>
        </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC11']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC11">
          <div class="form-group">
            <b>Factori cheie care influențează succesul</b>
            <Inputs v-model="Data.factors.sub1" />
          </div>
          <div class="form-group">
            <b>Modalitatea de măsurare a succesului</b>
            <Inputs v-model="Data.factors.sub2" />
          </div>
          <div class="form-group">
            <b
              >Indicator de performanţă cantitativ/calitativ utilizat pentru
              măsurarea succesului</b
            >
            <Inputs v-model="Data.factors.sub3" />
          </div>
        </div>
      </div>
    </div>

    <div
      id="Section_12"
      style="margin-top: 1.5rem"
      v-if="actives['S12']"
      class="LAccordionItem"
      :class="selected[12] ? 'LActive' : ''"
    >
      <div @click="select(12)" class="LAccordionItemHead">
        <span> <icon :valide="valide[12]" /> Anexe </span>
        <div v-show="!readonly">
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div
        class="LAccordionItemBody"
        :style="ACHcomputed['AC13']"
        :class="{ allReadonly: !isOwner }"
      >
        <div ref="AC13">
          <div class="TBParent">
            <table>
              <thead>
                <th style="width: 20px; padding-left: 0.5rem !important">
                  <input
                    :checked="selectedAllAnnexes"
                    @click="selectAllAnexes"
                    type="checkbox"
                  />
                </th>
                <th>Denumire</th>
                <th style="width: 23.75; text-align: right">Acțiuni</th>
              </thead>
              <tbody>
                <tr v-for="(file, i) in Data.annexes" :key="i">
                  <td
                    v-tooltip="
                      file[0].valide
                        ? ''
                        : 'Acest tip de fisier nu poate sa fie anexat la referatul de necesitate'
                    "
                  >
                    <input
                      v-if="file[0].valide"
                      v-model="file[0].selected"
                      type="checkbox"
                    />
                    <input v-else type="checkbox" disabled="disabled" />
                  </td>
                  <td>
                    <span>
                      {{ file[0].name
                      }}<span style="white-space: nowrap;"
                        >.{{ file[0].extension }}
                        <i
                          v-tooltip="{
                              '1': 'Fișierul este în proces de conversare.',
                              true: 'Fișierul a fost conversat cu succes.',
                              '2': 'Conversia fișierului a eșuat.',
                            }[file[0].generated]"
                          :class="{
                              '1': 'fas fa-sync fa-spin',
                              true: 'fas fa-check-circle checkedGreen',
                              '2': 'fas fa-times-circle failedRed',
                            }[file[0].generated]"
                        ></i>
                      </span>
                    </span>
                  </td>
                  <td
                    style="text-align: right;white-space: nowrap;padding-left: 2rem;"
                  >
                    <a
                      href="#"
                      style="color: #269ee1"
                      @click.prevent="$emit('getNeedFile', file[0])"
                      ><img
                        src="@/assets/media/eye.svg"
                        style="margin-top: -2px"
                        alt=""
                      />
                      <span>Deschide</span></a
                    >
                    |
                    <a
                      href="#"
                      style="color: #269ee1"
                      @click.prevent="$emit('downloadFile', file)"
                      :style="
                        file[1] ? 'pointer-events: none;color: #666;' : ''
                      "
                      ><img
                        src="@/assets/media/download_2.svg"
                        style="margin-top: -2px"
                        alt=""
                      />
                      <span>Descarcă</span></a
                    >
                    <span
                      v-if="file[1]"
                      style="display: inline-block; transform: translateY(2px)"
                    >
                      <i
                        class="fas fa-sync fa-spin"
                        style="
                          font-size: 2rem;
                          pointer-events: none;
                          margin-left: 0.5rem;
                        "
                      ></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-for="e in nrDinamicFieldEntity">
      <div
        :key="e._id"
        class="LAccordionItem"
        :class="selected[e._id] ? 'LActive' : ''"
      >
        <div
          :id="`Section_${e._id}`"
          @click="select(e._id)"
          class="LAccordionItemHead"
        >
          <span> <icon :valide="e.valide" /> {{ e.short }} </span>
          <div v-show="!readonly">
            <i class="fas fa-times"></i>
            <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
          </div>
        </div>
        <div
          class="LAccordionItemBody"
          :style="ACHcomputed[`AC${e._id + 1}`]"
          :class="{ allReadonly: !isOwner }"
        >
          <div :ref="`AC${e._id + 1}`">
            <div>
              <div class="form-group">
                <editor v-model="e.value" minHeight="150" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style>
.PaapFormNTD .LAccordionItemHead {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.PaapFormNTD .LAccordionItemBody > div {
  padding: 0 !important;
}

.PaapFormNTD .solidCheckboxes * {
  cursor: default;
}

.boldAllFormInputs .vInput {
  font-weight: bold !important;
}
</style>

<script>
import { ANNUAL_INSTITUTION_OBJECTIVES, BUGETARE } from "@/api.js";
import { mapGetters, mapMutations } from "vuex";
import LAcordion from "@/mixins/LAcordion.js";
import DatePicker from "@/mixins/DatePicker.js";
import TableComponent from "@/components/VerticalTable";
import Inputs from "@/components/MultyInputsFields";
import DialogModal from "@/components/DialogModal";
import ValIcon from "@/components/ReferateValideIcon";

import animateScrollTo from "animated-scroll-to";

const { validator } = window;

const sectionsNames = function() {
  return {
    0: {
      short: `Descrierea ${this.Data.achizitionCategory[1] || "itemilor"}`,
      full: `Descrierea ${this.Data.achizitionCategory[1] ||
        "itemilor"} care<br>urmează să fie achiziționate`,
    },
    1: {
      short: `Obiectul Referat de necesitate`,
      full: `Achiziția care face obiectul <br>acestui Referat de necesitate`,
    },
    2: {
      short: `Destinaţia achiziţiei`,
      full: `Destinaţia achiziţiei prin care urmează <br>a fi satisfăcută necesitatea`,
    },
    3: {
      short: `Fundamentarea`,
      full: `Fundamentarea Necesitatii`,
    },
    4: {
      short: `Contribuția achiziției la îndeplinirea obiectivelor`,
      full: `Contribuţia satisfacerii necesităţii<br> la îndeplinirea obiectivelor<br> autorităţii contractante sau la <br>funcţionarea acesteia`,
    },
    5: {
      short: `Obiectivele autorităţii contractante`,
      full: `Obiectivul/obiectivele autorităţii contractante <br>la care contribuie satisfacerea necesităţii <br>şi cum se realizează`,
    },
    6: {
      short: `Obiectivele proiectului`,
      full: `Obiectivul/obiectivele proiectului la îndeplinirea <br>căruia/cărora contribuie satisfacerea necesităţii <br>şi legătura cu activitatea autorităţii contractante`,
    },
    7: {
      short: `Obiectivele din strategia locală/regională/naţională`,
      full: `Obiectivul/obiectivele din strategia <br>locală/regională/naţională la a cărei realizare <br>contribuie satisfacerea necesităţii şi cum`,
    },
    8: {
      short: `Beneficiile obținute`,
      full: `Beneficii care urmează a fi obţinute după satisfacerea <br>necesităţii sau problemele care vor fi rezolvate <br>după satisfacerea necesităţii ca urmare <br>a atingerii obiectivelor propuse`,
    },
    9: {
      short: `Justificarea modalităţii`,
      full: `Justificarea alegerii modalităţii<br> de satisfacerea necesităţii`,
    },
    10: {
      short: `Nevoia în contextul legislației`,
      full: `Necesitatea în contextul <br>legislaţiei în achiziţii publice`,
    },
    11: {
      short: `Stadiul rezultatelor`,
      full: `Descrierea obiectului contractului şi precizarea <br>stadiului în care trebuie să fie rezultatul <br>lucrărilor la sfârşitul contractului`,
    },
    12: {
      short: `Cod CPV`,
      full: `Cod CPV pentru descrierea <br>obiectului contractului`,
    },
    13: {
      short: `Descrierea ${this.Data.achizitionCategory[1] || "itemilor"}`,
      full: `Valoarea estimată a ${this.Data.achizitionCategory[1] ||
        "itemilor"} <br>care urmează să fie achiziţionate`,
    },
    14: {
      short: `Documentele suport`,
      full: `Documentele suport/informaţiile <br>care au fost consultate/utilizate <br>la stabilirea valorii estimate`,
    },
    15: {
      short: `Calendarul achizitiilor`,
      full: `Calendarul procesului de achiziţie publică<br> în vederea satisfacerii necesităţii`,
    },
    16: {
      short: `Semnarea contractului`,
      full: `Semnarea contractului/semnarea acordului-cadru`,
    },
    17: {
      short: `Semnarea primului contract`,
      full: `Semnarea primului contract <br>subsecvent [doar pentru acord-cadru]`,
    },
    18: {
      short: `Realizarea evaluării`,
      full: `Realizarea evaluării îndeplinirii obligaţiilor <br>contractuale de către contractor`,
    },
    19: {
      short: `Realizarea evaluări finale`,
      full: `Realizarea evaluării modului în care se vor <br>materializa beneficiile planificate, la finalizarea <br>contractului/acordului-cadru`,
    },
    20: {
      short: `Finalizarea implementării contractului`,
      full: `Finalizarea implementării contractului <br>(recepţie la terminarea lucrărilor/punerea în funcţiune/finalizarea <br>prestării serviciilor sau finalizarea livrării produselor)`,
    },
    21: {
      short: `Perspectiva termen scurt`,
      full: `Perspectiva pe termen scurt: fondurile<br> alocate pentru satisfacerea necesităţii şi<br> poziţia bugetară a acestora`,
    },
    22: {
      short: `Fondurile alocate`,
      full: `Fondurile alocate pentru <br>satisfacerea necesităţii`,
    },
    23: {
      short: `Sursa de finantare`,
      full: `Sursa de finantare și, după caz, <br>poziţia în buget/propunerea de buget`,
    },
    24: {
      short: `Perspectiva termen lung`,
      full: `Perspectiva pe termen lung: accesul <br>la toate şi fiecare dintre resursele <br>complementare - inclusiv resurse <br>financiare - pentru utilizarea sau exploatarea <br>rezultatului contractului, ca <br>urmare a satisfacerii necesităţii`,
    },
    25: {
      short: `Modalitatea utilizată`,
      full: `Modalitatea utilizată pentru <br>descrierea caracteristicilor`,
    },
    26: {
      short: `Trimiteri caiet sarcini`,
      full: `Caietul de sarcini include trimiteri la următoarele categorii <br>de standarde (ordinea de prioritate a standardelor <br>astfel cum este stabilită la art. 156 alin.(1), lit b) <br>din Legea 98/2016):`,
    },
    27: {
      short: `Evaluări tehnice europene`,
      full: `Evaluări tehnice europene <br>[acolo unde este aplicabil]`,
    },
    28: {
      short: `Specificaţii tehnice comune`,
      full: `Specificaţii tehnice comune <br>[acolo unde este aplicabil]`,
    },
    29: {
      short: `Sisteme de referinţă tehnice instituite de organisme de standardizare europene`,
      full: `Sisteme de referinţă tehnice <br>instituite de organisme de standardizare <br>europene [acolo unde este aplicabil]`,
    },
    30: {
      short: `Factori cheie`,
      full: `Factori cheie pentru succesul <br>procesului de achiziţie publică <br>şi satisfacerea necesităţii`,
    },
    31: {
      short: `Modalitatea de centralizare în PAAP`,
      full: `Modalitatea de centralizare în PAAP`,
    },
    32: {
      short: `Clasificație bugetară`,
      full: `Clasificație bugetară`,
    },
  };
};

export default {
  mixins: [LAcordion, DatePicker],
  components: {
    TableComponent,
    Inputs,
    icon: ValIcon,
  },
  props: {
    Data: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    reference: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    selected: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    valide: {
      required: false,
      type: Object,
      default: null,
    },
    syncSel: {
      required: false,
      type: Number,
      default: 0,
    },
    sel: {
      required: false,
      type: Number,
      default: 0,
    },
    genKey: {
      required: false,
      type: Number,
      default: 0,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      NAMES: sectionsNames.call(this),
      multiSelectApiModule: this.reference.nryear
        ? (x, y) =>
            ANNUAL_INSTITUTION_OBJECTIVES.get(
              x,
              y,
              `eq=year,${this.reference.nryear}&isActive=1`
            )
        : false,
      optiuniCentralizare: [],
      optiuneCentralizare: this.Data.modalitateDeCentralizare,
      budgetClassificationFunctional: {},
      budgetClassificationEconomic: {},
      bugetareGet: BUGETARE.get,
      modalitateDeCentralizareTypes: [],
    };
  },
  watch: {
    budgetClassificationFunctional(val) {
      this.updateBudgetClassification();
    },
    budgetClassificationEconomic(val) {
      this.updateBudgetClassification();
    },
    genKey() {
      this.$nextTick(this.resetLAcordion);
    },
    readonly: {
      handler(x) {
        Object.keys(this.selected).forEach((e) => {
          this.selected[e] = x;
        });
        if (x) this.setCurrentReferenceSidebar(false);
        else this.showCRS();
      },
      immediate: true,
    },
    selected: {
      handler(x) {
        this.syncSelected();
      },
      deep: true,
    },
    syncSel() {
      this.syncSelected();
    },
    sel(x) {
      this.select(x);
    },
    actives: {
      handler(x) {
        Object.entries(x)
          .filter((e) => !e[1])
          .forEach((e) => {
            const x = e[0]?.slice(1);
            this.selected[x] = false;
            const autoClear = (obj, type) =>
              this.isObject(obj)
                ? Object.keys(obj).forEach((i) => {
                    obj[i] = type == "array" ? [""] : "";
                  }) || true
                : false;

            if (x == "8") {
              this.Data.perspectives["fonds"] = "";
            }
            if (x == "9") {
              this.Data.longPerspective = "";
            }
            if (x == "10") {
              autoClear(this.Data.factors, "array");
            }
            if (x == "12") {
              this.Data.annexes.forEach((e) => (e[0].selected = false));
            }
          });
        this.syncSelected();
      },
      deep: true,
    },
    Data: {
      handler(x) {
        this.syncValide();
      },
      deep: true,
    },

    optiuneCentralizare(x) {
      this.Data.modalitateDeCentralizare = x;
    },

    "Data.modalitateDeCentralizareType": {
      handler(x) {
        this.initModalitateDeCentralizareType(x);
      },
    },
    showSignFirstContract() {
      this.Data.calendar.signFirstContract = null;
    },
    singleCalendarInput() {
      this.Data.calendar.signContract = null;
    },
  },
  computed: {
    ...mapGetters(["getCRS"]),
    actives() {
      return this.Data.activeSections;
    },
    canSetModalitateDeCentralizare() {
      const role = this.getUserRole(true, true);

      return (
        role.institutionId == this.reference.institution.id &&
        role.role == "supervizor_achizitii" &&
        this.Data.cpvType == 1
      );
    },

    canSetBudgetClassification() {
      const role = this.getUserRole(true, true);
      return (
        role.institutionId == this.reference.institution.id &&
        role.role == "responsabil_buget"
      );
    },
    totalPrice() {
      return this.prepareLocaleNumber(
        this._Sum(this.Data.priceUnits.map((e) => this.getLocaleNumber(e[5])))
      );
    },

    totalPriceWithoutTva() {
      return this.prepareLocaleNumber(
        this._Sum(this.Data.priceUnits.map((e) => this.getLocaleNumber(e[3])))
      );
    },
    selectedAllAnnexes() {
      return !this.Data.annexes.find((e) => !e[0].selected && e[0].valide);
    },
    showSignFirstContract() {
      return this.Data.achizitionDesc.type == "two";
    },
    singleCalendarInput() {
      return this.Data.achizitionDesc.type == "three";
    },
    calendarNr() {
      return this.showSignFirstContract
        ? ["1.", "2.", "3.", "4.", "5."]
        : ["1.", "", "2.", "3.", "4."];
    },
    getSelectableDateRange() {
      const x = this.Data.calendar;
      const map = {
        0: "signContract",
        1: "signFirstContract",
        2: "release",
        3: "fulfillmentOfObligations",
        4: "finishContract",
      };
      const m = Object.entries(map);
      const d = (date) => new Date(date);

      const prepare = {};

      for (let i = 0; i < m.length; i++) {
        prepare[i] = {
          from: null,
          to: null,
        };
        for (const e of m) {
          if (!x[e[1]]) continue;
          const i2 = +e[0];
          if (i > i2) {
            prepare[i].from = d(x[e[1]]);
          }
          if (i < i2) {
            prepare[i].to = d(x[e[1]]);
          }
          if (prepare[i].to) break;
        }
      }

      return prepare;
    },
    nrDinamicFieldEntity() {
      return this.Data.nrDinamicFieldEntity.filter(
        (e) => e.data?._isDeleted !== true
      );
    },
    isOwner() {
      const role = this.getUserRole(true);
      return this.reference.createdByUserRole == role;
    },
  },
  methods: {
    ...mapMutations(["setCurrentReferenceSidebar"]),

    updateBudgetClassification() {
      const x = [];
      if (this.budgetClassificationEconomic) {
        x.push(this.budgetClassificationEconomic);
      }
      if (this.budgetClassificationFunctional) {
        x.push(this.budgetClassificationFunctional);
      }

      this.Data.budgetClassification = x;
      //this.syncValide()
    },
    initModalitateDeCentralizareType(x) {
      this.optiuneCentralizare = null;
      this.optiuniCentralizare = [];

      if (!x) return;

      let needs = [];

      if (this.reference.complexIncludedNeeds) {
        needs = [
          ...this.reference.complexIncludedNeeds,
          this.reference.dominantNeedOBJECT,
        ];
      } else {
        needs = [this.reference.need];
      }

      for (const need of needs) {
        const obj = {};

        if (x.key == "cpvCode") {
          obj["key"] = need.product.cpvCategory[x.key];
          obj["label"] = need.product.cpvCategory["name"];
        } else {
          obj["key"] = need.product.cpvCategory[x.key + "Code"];
          obj["label"] = need.product.cpvCategory[x.key];
        }

        this.optiuniCentralizare.push(obj);
      }

      if (this.optiuniCentralizare.length == 1) {
        this.optiuneCentralizare = this.optiuniCentralizare[0];
      }
    },
    initModalitateDeCentralizareTypes() {
      const products = [
        { label: "Grup", key: "group" },
        { label: "Clasă", key: "class" },
        { label: "Categorie", key: "category" },
      ];

      const others = [{ label: "Cod CPV", key: "cpvCode" }];

      if (
        (this.reference?.dominantNeedOBJECT &&
          this.reference?.dominantNeedOBJECT?.product?.cpvCategory?.cpvType ==
            1) ||
        (this.reference.need &&
          this.reference.need.product.cpvCategory.cpvType == 1)
      ) {
        this.modalitateDeCentralizareTypes = products;
      } else {
        this.modalitateDeCentralizareTypes = others;
      }
    },

    prepareSearchEconomic(x) {
      return ["economic", ["columnsOr", `titlu,name||${x}`]];
    },

    prepareSearchFunctional(x) {
      return ["functional", ["columnsOr", `titlu,name||${x}`]];
    },

    getDefaultDate() {
      if (new Date().getFullYear() != this.reference.nryear) {
        return new Date("1-1-" + this.reference.nryear);
      }

      return new Date();
    },

    dateSelectableManager(x, z) {
      let o = new Date();

      if (new Date().getFullYear() != this.reference.nryear) {
        o = new Date("1-1-" + this.reference.nryear);
      }

      if (o.setDate(o.getDate() - 1) > x) return true;

      const q = this.getSelectableDateRange[z];
      if (!q) return false;

      if ((q.from && x <= q.from) || (q.to && x >= q.to)) {
        return true;
      }
      return false;
    },

    selectAllAnexes() {
      const x = this.selectedAllAnnexes;
      this.Data.annexes.forEach((e) =>
        e[0].valide ? (e[0].selected = !x) : false
      );
    },

    unSelectAll() {
      Object.keys(this.selected).forEach((e) => (this.selected[e] = false));
    },

    syncValide() {
      const x = this.Data;
      const y = this.valide;

      y[0] = validator.call(x.achizitionDesc, ["table"]);
      y[1] = validator.call(x.fundamentNeed);
      y[2] =
        validator.call(x.obectives1 /* , ['ob1'] */) &&
        validator.call(x.identifiedNeed);
      y[3] = validator.call(x.justificationChoice);
      y[4] = validator.call(x.legislationContext);
      y[5] = validator.call(x.docSuports, ["desc"]);
      y[6] = validator.call(
        x.calendar,
        this.singleCalendarInput
          ? [
              "signFirstContract",
              "release",
              "fulfillmentOfObligations",
              "finishContract",
            ]
          : this.showSignFirstContract
          ? []
          : ["signFirstContract"]
      );
      y[8] = validator.call(x.perspectives.fonds);
      y[10] = validator.call(x.factors);
      y[11] = true;
      y[12] = !!~x.annexes.findIndex(
        (e) => e[0].selected && e[0].generated === true
      );
      y[13] =
        validator.call(x.modalitateDeCentralizare) &&
        validator.call(x.modalitateDeCentralizareType);
      y[14] =
        x.budgetClassification.length == 2 &&
        x.budgetClassification.findIndex((el) => el.type == "economic") != -1 &&
        x.budgetClassification.findIndex((el) => el.type == "functional") != -1;

      this.syncSelected();
    },
    select(index) {
      if (!Number.isInteger(index)) return;
      const x = this.selected[index];
      this.unSelectAll();

      if (index > 7 && this.actives[`S${index}`] === false) {
        this.syncSelected();
        this.Data.activeSections[`S${index}`] = !this.Data.activeSections[
          `S${index}`
        ];
      }

      this.selected[index] = !x;
      window.requestAnimationFrame(() => {
        this.focusToCurrentSection(index);
      });
    },
    syncSelected() {
      const x = this.selected;
      const h = this.NAMES;
      const y = [
        {
          isCurrent: x[7],
          short: "Informații Referat",
          full: "Informații Referat",
          id: 7,
        },
        {
          isCurrent: x[0],
          id: 0,
          valide: this.valide[0] || false,
          ...h[0],
        },

        {
          isCurrent: x[13],
          id: 13,
          valide: this.valide[13] || false,
          show: this.actives["S13"],
          ...h[31],
        },
        {
          isCurrent: x[14],
          id: 14,
          valide: this.valide[14] || false,
          active: this.actives["S14"],
          ...h[32],
        },

        {
          isCurrent: x[1],
          short: "Fundamentarea necesităţii",
          full: "Fundamentarea necesităţii",
          id: 1,
          valide: this.valide[1] || false,
        },
        {
          isCurrent: x[2],
          id: 2,
          valide: this.valide[2] || false,
          ...h[4],
        },
        {
          isCurrent: x[3],
          id: 3,
          valide: this.valide[3] || false,
          ...h[9],
        },
        {
          isCurrent: x[4],
          id: 4,
          valide: this.valide[4] || false,
          ...h[10],
        },
        {
          isCurrent: x[5],
          id: 5,
          valide: this.valide[5] || false,
          ...h[11],
        },
        {
          isCurrent: x[6],
          id: 6,
          valide: this.valide[6] || false,
          ...h[15],
        },

        {
          isCurrent: x[8],
          id: 8,
          valide: this.valide[8] || false,
          active: this.actives["S8"],
          ...h[21],
        },
        {
          isCurrent: x[11],
          id: 11,
          valide: this.valide[11] || false,
          active: this.actives["S11"],
          ...h[24],
        },
        {
          isCurrent: x[10],
          id: 10,
          valide: this.valide[10] || false,
          active: this.actives["S10"],
          ...h[30],
        },

        {
          isCurrent: x[12],
          short: "Anexe",
          full: "Anexe",
          id: 12,
          valide: this.valide[12] || false,
          active: this.actives["S12"],
        },
      ].concat(
        this.nrDinamicFieldEntity.map((e) => {
          e.valide = validator.call(e.value) || false;
          e.isCurrent = x[e._id];

          return {
            ...e,
            id: e._id,
          };
        })
      );
      if (!this.readonly) {
        this.setCurrentReferenceSidebar({ ...this.getCRS, sections: y });
      }
      return y;
    },
    focusToCurrentSection(index) {
      index = index || Object.values(this.selected).findIndex((e) => e);

      const convertor = {
        9: 10,
        10: 11,
      };
      index = convertor[index] || index;
      if (~index) {
        const el = document.getElementById(`Section_${index}`);
        const el2 = document.querySelector(".Paap_Main > div");
        if (el && el2) {
          const q = () =>
            animateScrollTo(el, {
              verticalOffset: 0,
              elementToScroll: el2,
              cancelOnUserAction: false,
            }).catch(() => {});
          q();
          setTimeout(q, 450);
        }
        this.$nextTick(() => {
          const el = document.getElementById(`col1_reference_section_${index}`);
          const el2 = document.getElementById("col1_references");
          if (el && el2) {
            animateScrollTo(el, {
              verticalOffset: 0,
              elementToScroll: el2,
              cancelOnUserAction: true,
            }).catch(() => {});
          }
        });
      }
    },
    showCRS() {
      this.setCurrentReferenceSidebar({
        opened: true,
        sectionsCount: 10,
        sections: this.syncSelected(),
        selectSection: this.select,
        actives: this.actives,
        createSection: () =>
          this.$modal.show(
            DialogModal,
            {
              target: "AddDynamicSection",
              title: "Adaugă secțiune",
              inputs: [
                {
                  label: "Denumire secțiune",
                  id: "name",
                  errReason: "Introduceți denumirea secțiunii dinamice.",
                },
              ],
              closeBtnText: "Anulează",
              button: {
                type: 2,
                value: "Adaugă",
                handler: ({ name }) => this.$emit("addSection", name),
              },
            },
            {
              name: "AddDynamicSection",
              adaptive: true,
              width: "650",
              height: "400",
            }
          ),
        editDynamicSection: (data) => {
          this.$modal.show(
            DialogModal,
            {
              target: "EditDynamicSection",
              title: "Editează secțiune",
              inputs: [
                {
                  label: "Denumire secțiune",
                  id: "name",
                  errReason:
                    "Introduceți o nouă denumire a secțiunii dinamice.",
                  value: data.short,
                  checker: (e) => !!e && e?.trim() != data.short?.trim(),
                },
              ],
              closeBtnText: "Anulează",
              secondaryButton: {
                type: 3,
                value: "Sterge",
                handler: () => {
                  this.$modal.show(
                    DialogModal,
                    {
                      target: "ConfirmDeletingDynamicSection",
                      title: "Confirmare de ștergere",
                      content: `<h4>Ești sigur că vrei să ștergi secțiunea dinamică: <b>${data.short}</b>?</h4>`,
                      closeBtnText: "Anulează",
                      button: {
                        type: 3,
                        value: "Sterge",
                        handler: () => this.$emit("removeDynamic", data),
                      },
                    },
                    {
                      name: "ConfirmDeletingDynamicSection",
                      adaptive: true,
                      width: "650",
                      height: "350",
                    }
                  );
                },
              },
              button: {
                type: 2,
                value: "Editează",
                handler: ({ name }) => {
                  const find = this.nrDinamicFieldEntity.find(
                    (i) => i._id == data._id
                  );
                  if (find) {
                    find.short = name.trim();
                  } else {
                    this.$toastr.e("Anunțați administratorul.");
                  }
                },
              },
            },
            {
              name: "EditDynamicSection",
              adaptive: true,
              width: "650",
              height: "400",
            }
          );
        },
      });
      this.syncValide();
    },
  },
  created() {

    this.budgetClassificationEconomic = this.Data.budgetClassification.find(
      (el) => el.type == "economic"
    );
    this.budgetClassificationFunctional = this.Data.budgetClassification.find(
      (el) => el.type == "functional"
    );

    this.initModalitateDeCentralizareTypes();
    this.initModalitateDeCentralizareType(
      this.Data.modalitateDeCentralizareType
    );
    this.optiuneCentralizare = this.Data.modalitateDeCentralizare;
  },
  beforeDestroy() {
    this.setCurrentReferenceSidebar(false);
  },
};
</script>

<style lang="scss">
.mod-centralizare-select {
  ul {
    position: initial;
    height: fit-content;
  }
}
</style>
