<template>
  <div style="padding-left: 1.75rem">
    <clasic-modal v-model="historyModal" extendedMode="1">
      <history
        :referenceId="historyReferenceId"
        @close="historyModal = false"
      />
    </clasic-modal>
    <div class="col2_head">
      <span
        ><router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
        {{ $route.name }}
        <div class="subHeadTitle">{{ $route.name }}</div></span
      >
      <div v-if="generateReferenceData" class="right">
        <div
          @click="cancelRf()"
          class="goBack"
          style="margin: 0.5rem 0 0.5rem; position: absolute; bottom: 0"
        >
          <img src="../assets/media/vectors/arrow_back.svg" alt="" />
        </div>
      </div>
    </div>
    <template v-if="!generateReferenceData">
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="tableReload"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :apiModuleName="apiModuleName"
          :disabledCols="disabledCols"
          @Btn2="editReference"
          @Btn5="openAddress"
          @Btn13="viewHistory"
          @Btn14="previewReference"
          @Btn3="cancellReference"
          @Btn40="renameReference"
          @Btn42="externalSignReference"
          @totalRecords="(x) => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
    <generate-rf
      v-else
      :genKey="genKey"
      :Data="generateReferenceData"
      v-model="I"
      @cancelRf="cancelRf"
      @viewPdf="viewPdf"
      @changeStatus="changeStatus"
      @value="changeVal"
    />
  </div>
</template>

<style src="@/assets/paap.css"></style>
<style src="@/assets/LAcordion.css"></style>

<script>
import PermisionsMixin from "@/mixins/referencesPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import PathRefresh from "@/mixins/mainPathRefresh.js";
import {
  REFERENCE_PAGINATED,
  REFERENCE_ACHIZITII_DIRECTE,
  REFERENCE_PROCEDURI_SIMPLIFICATE,
  REFERENCE_LICITATII_PUBLICE,
  REFERENCE_ONCE,
  REFERENCE_MODIFY,
  REFERENCE_CHANGE_STATUS,
  REFERENCE_VERSIONS,
  REFERENCE_EXTERNAL_SIGNATURE,
  REFERENCE_DELETE_PENDING,
} from "@/api.js";
import { mapMutations } from "vuex";

import TableParent from "@/components/TableParent";
import Generate from "@/components/ReferateGenerate";
import History from "@/components/NeedReferencesHistory";
import DialogModal from "@/components/DialogModal";
import DeleteNeedReference from "@/components/DeleteNeedReference";

const formData = () => ({
  fundamentNeed: "",
  identifiedNeed: "",
  longPerspective: "",
  justificationChoice: "",
  relevantFactors: "",
  cpvType: 0,
  achizitionCategory: [],
  nrDinamicFieldEntity: [],
  activeSections: {
    S8: false,
    S10: false,
    S11: false,
    S12: false,
  },
  factors: {
    sub1: [],
    sub2: [],
    sub3: [],
  },
  referenceInfo: {
    user: "",
    department: "",
    institution: "",
    date: "",
  },
  annexes: {
    an1: "",
    an2: "",
    an3: "",
    an4: "",
    an5: "",
    an6: "",
  },
  selectedAnnexes: [],
  achizitionDesc: {
    table: {
      headers: [
        "Denumire propunere",
        "Nume, prenume solicitant / Id ConectX",
        "Data și ora creării",
        "Codul CPV",
        "Unitatea de măsură",
        "Cantitatea",
        "Valoarea estimată bucată, fără TVA",
        "Valoarea estimată bucată, cu TVA",
        "Valoarea estimată totală, fără TVA",
        "Valoarea estimată totală, cu TVA",
      ],
      data: [],
      navKeyIndex: 3,
    },
    destination: "",
    type: "",
  },
  obectives1: {
    ob1: [],
    ob2: [],
    ob3: [],
    ob4: [],
  },
  legislationContext: {
    cpvCodes: [],
  },
  docSuports: {
    desc: "",
    requirements: [],
  },
  calendar: {
    signContract: null,
    signFirstContract: null,
    finishContract: null,
    fulfillmentOfObligations: null,
    release: null,
  },
  perspectives: {
    fonds: "",
    sources: null,
  },
  annexes: [],
  priceUnits: [],
  s10: [],
  budgetClassification: [],
  modalitateDeCentralizareType: null,
  modalitateDeCentralizare: null,
});

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  inject: ["ReferenceReject"],
  name: "Referate",
  mixins: [PermisionsMixin, updateRoleStatus, PathRefresh],
  components: {
    TableParent,
    History,
    "generate-rf": Generate,
  },
  data() {
    return {
      tableReload: 0,
      historyModal: false,
      historyReferenceId: null,
      genKey: 0,
      tableHead: [
        {
          title: "Denumire",
          queryKey: "name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Anul de încadrare",
          queryKey: "nryear",
          bottomHead: {
            quikFiltre: "date",
            dateType: "year",
            dateFormat: "YYYY",
          },
        },
        {
          title: "Instituție",
          queryKey: "institution.name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Structură organizatorică",
          queryKey: "department.name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Inițiator (nume)",
          queryKey: "createdBy:userName", //createdBy:allUser
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Inițiator (rol)",
          queryKey: "createdByUserRoleName",
          bottomHead: {
            quikFiltre: "list",
            options: [
              {
                label: "Admin instituție",
                value: "admin_institutie",
              },
              {
                label: "Șef instituție",
                value: "sef_institutie",
              },
              {
                label: "Supervizor",
                value: "supervisor",
              },
              {
                label: "Funcționar",
                value: "functionar",
              },
              {
                label: "Responsabil achiziții",
                value: "responsabil_achizitii",
              },
              {
                label: "Supervizor achiziții",
                value: "supervizor_achizitii",
              },
              {
                label: "Responsabil achiziții externe",
                value: "responsabil_achizitii_extern",
              },
            ],
          },
        },
        {
          title: "Inițiator (id)",
          queryKey: "createdByUser.id",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cod CPV (dominant)",
          queryKey: "need.product.cpvCategory.cpvCode",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoarea totală estimată",
          queryKey: "sumnr",
          bottomHead: {
            quikFiltre: "number",
          },
        },
        /*         {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: 'need.needIsSocial',
          bottomHead: {
            tooltip: 'Caracter nevoie dominantă',
            iconsMap: [
              {
                tooltip: 'Social',
                icon: 'fas fa-circle',
                color: 'green',
              },
              {
                tooltip: 'Non-social',
                icon: 'fas fa-circle',
                color: '#8a6d3b',
              },
            ],
            valuesMap: { value: [false, false] },
            queryMap: ['1', '0'],
          },
        }, */
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "paap",
          bottomHead: {
            tooltip: "Status paap",
            iconsMap: [
              {
                tooltip: "Participă în PAAP",
                icon: "fas fa-file",
                color: "#569a36",
              },
              {
                tooltip: "Nu participă în PAAP",
                icon: "far fa-file",
                color: "#569a36",
              },
            ],
            valuesMap: { value: [false, false] },
            queryMap: [["null"], ["null", "eqn"]],
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "needReferateStatus",
          bottomHead: {
            tooltip: "Status referat",
            quikFiltre: "statusReference",
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          bottomHead: {
            quikFiltre: "clear",
          },
        },
      ],
      generateReferenceData: false,
      I: formData(),
      tableModule: this.getApiModule(),
      apiModuleName: this.getApiModule(true),
      totalRecords: 0,
    };
  },
  computed: {
    disabledCols() {
      const disabledColsMap = {
        1: [],
        2: [3],
        3: [3],
      };
      return disabledColsMap[this.currentRoleStatus] || [];
    },
    openingPanel() {
      return Number.isInteger(+this.$route.params.id);
    },
  },
  watch: {
    historyHistory(x) {
      if (!x) this.historyReferenceId = null;
    },
    generateReferenceData(x) {
      if (!x) this.reloadPage();
    },
    ReferenceReject: {
      handler(x) {
        x = x?.ReferenceReject;

        this.changeStatus(x.id, x.status, x.callback, true, x.rejectedComment);
      },
      deep: true,
    },
    $route() {
      this.syncUrl();
    },
  },
  methods: {
    ...mapMutations(["setReferenceIdForPreview", "addWindow"]),
    getApiModule(stringMode) {
      const routesMap = {
        "/referate": REFERENCE_PAGINATED,
        /*        '/achizitii_directe': REFERENCE_ACHIZITII_DIRECTE,
        '/proceduri_simplificate': REFERENCE_PROCEDURI_SIMPLIFICATE,
        '/licitatii_publice': REFERENCE_LICITATII_PUBLICE, */
      };
      const find = routesMap[this.getSecondPath()];
      return find ? (stringMode ? find.name : find) : routesMap[0];
    },
    changeVal(x) {
      if (this.isObject(x)) {
        this.$set(this, "I", x);
        this.$nextTick(() => {
          this.genKey++;
        });
      }
    },
    getComm(data) {
      const commMap = (status) =>
        ({
          rejected: {
            title: "Respinge referat",
            labels: ["Respins", "respingerii", "Motivul respingerii"],
            button: { value: "Respingere", type: 3 },
          },
          approved: {
            title: "Aprobă referat",
            labels: ["Aprobat", "aprobării", "Observații"],
            button: { value: "Aprobare", type: 2 },
          },
          /*     cancelled: {
            title: 'Anulează referat',
            labels: ['Anulat', 'anulării', 'Motivul anulării'],
            button: { value: 'Anulare', type: 3 },
          }, */
        }[status] || {
          title: "-",
          labels: [],
          button: {},
        });
      this.addWindow({
        modal: 11,
        rejectData: {
          referenceData: data,
        },
        ...commMap(data?.status),
      });
    },
    viewHistory(item) {
      if (!Number.isInteger(item?.id)) return;

      this.historyModal = true;
      this.historyReferenceId = item.id;
    },
    reloadPage() {
      this.I = formData();
      this.toTable();
      this.tableReload++;
    },
    viewPdf(id, blob) {
      this.setReferenceIdForPreview(
        blob
          ? { file: blob }
          : {
              data: {
                id: id,
              },
              origin: "REFERENCE_GET_PDF",
            }
      );
    },
    findTableItem(index) {
      if (!Number.isInteger(index)) return;
      return this.data.find((e) => e.NR === index) || undefined;
    },
    renameReference(item) {
      if (this.isObject(item) && this.isNumber(item.id, true)) {
        const referenceName = this.isString(item.name) ? item.name.trim() : "";

        this.$modal.show(
          DialogModal,
          {
            target: "RenameNeedReference",
            title: "Redenumește Referat Necesitate",
            inputs: [
              {
                label: "Denumire referat",
                id: "name",
                value: referenceName,
                errReason:
                  "Introduceți o nouă denumire a referatului de necesitate.",
                checker: (e) => !!e && e.trim() && e.trim() !== referenceName,
              },
            ],
            closeBtnText: "Anulează",
            button: {
              type: 2,
              value: "Redenumește",
              handler: ({ name }) => {
                if (!this.isString(name, false, true)) return;

                const error = (msg) => {
                  this.$toastr.e(msg || "Redenumirea referatului a eșuat.");
                  this.setLoad(false);
                  this.reloadPage();
                };

                this.setLoad(true);
                REFERENCE_MODIFY(item.id, { name })
                  .then((res) => {
                    if (this.checkHttpStatusCode(res?.meta.HttpStatusCode)) {
                      this.$toastr.s("Referatul a fost redenumit.");
                      this.setLoad(false);
                      this.reloadPage();
                    } else {
                      error();
                    }
                  })
                  .catch(error);
              },
            },
          },
          {
            name: "RenameNeedReference",
            adaptive: true,
            width: "650",
            height: "400",
          }
        );
      }
    },
    editReference(item) {
      if (Number.isInteger(item?.id)) {
        this.$router.push(`${this.$route.path}/${item.id}`);
      }
    },
    previewReference(item) {
      if (Number.isInteger(item.id)) {
        this.viewPdf(item.id);
      }
    },
    changeStatus(id, status, callback, confirm, comment) {
      if (status != "signature_waiting") {
        if (!confirm) {
          this.getComm({ id, status, callback });
          return;
        }
      }
      if (!Number.isInteger(id) || !this.isString(status)) return;
      this.setLoad(true);
      REFERENCE_CHANGE_STATUS(id, {
        status,
        reason: comment || "",
      })
        .then((res) => {
          this.setLoad(false);
          if (typeof callback == "function") callback(res);
        })
        .catch((e) => {
          this.$toastr.e(e || "A apărut o eroare.");
          this.setLoad(false);
          if (this.generateReferenceData) this.cancelRf();
          else this.reloadPage();
        });
    },
    cancellReference(item) {
      if (!Number.isInteger(item?.id)) {
        return;
      }

      this.$modal.show(
        DialogModal,
        {
          target: "DeleteNeedReference",
          title: "Șterge Referat Necesitate",
          inputs: [
            {
              id: "addObjective",
              type: "component",
              component: DeleteNeedReference,
              componentProps: {
                needReference: item,
              },
              disableAlert: true,
              checker: () => true,
            },
          ],
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Referatul a fost șters a eșuat.");
                this.setLoad(false);
                this.reloadPage();
              };

              this.setLoad(true);
              REFERENCE_DELETE_PENDING(item.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Referatul a fost șters.");
                    this.setLoad(false);
                    this.reloadPage();
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "DeleteNeedReference",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );

      /*  this.changeStatus(item.id, 'cancelled', res => {
        if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
          this.$toastr.s(`Referatul a fost anulat cu succes`, 'Succes!')
        } else {
          this.$toastr.e(`Anularea referatului a decurs cu errori.`)
        }
        this.reloadPage()
      }) */
    },
    externalSignReference(item) {
      if (!Number.isInteger(item?.id)) {
        return;
      }

      const error = (msg) => {
        this.$toastr.e(msg || "A apărut o eroare.");
        this.setLoad();
      };

      this.setSafeLoad();
      REFERENCE_EXTERNAL_SIGNATURE(item.id)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            window.open(res.data.result.url, "_blank");
            this.setLoad();
          } else {
            error(res.response);
          }
        })
        .catch(error);
    },

    openAddress(item) {
      window.open(item.conectxAddressUrl, "_blank");
    },
    signReference(item, pos) {
      if (item) {
        this.changeStatus(item.id, pos ? "approved" : "rejected", (res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s(
              `Referatul a fost ${pos ? "aprobat" : "respins"} cu succes`,
              "Succes!"
            );
          } else {
            this.$toastr.e(
              `${
                pos ? "Aprobarea" : "Respingerea"
              } referatului a decurs cu errori.`
            );
          }
          this.reloadPage();
        });
      }
    },
    cancelRf(immediately) {
      const fn = () => (this.generateReferenceData = false);
      if (immediately) fn();
      else this.simulateLoad(fn);
    },
    prepareData(row) {
      const getStatusIcon = (s) =>
        ({
          draft: {
            border: "left",
            icon: "far fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2rem;",
            tooltip: "Draft",
          },
          signature_waiting: {
            border: "left",
            icon: "fas fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2rem;",
            tooltip: "Așteptare semnare",
          },

          in_progress_signature: {
            border: "left",
            icon: "fas fa-pen-nib",
            css: "padding: 0.5rem;color: #F2994A; font-size: 2rem;",
            tooltip: "În curs de aprobare",
          },
          rejected: {
            border: "left",
            icon: "fas fa-times-circle",
            css: "padding: 0.5rem;color: #ec4a4a;font-size: 2rem;",
            tooltip: "Respins",
          },
          approved: {
            border: "left",
            icon: "fas fa-clipboard-check",
            css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
            tooltip: "Aprobat",
          },
          completion: {
            border: "left",
            icon: "fas fa-pencil",
            css: "padding: 0.5rem;color: #F2994A;font-size: 2rem;",
            tooltip: "Trimis spre completare",
          },
          completed: {
            border: "left",
            icon: "fas fa-pencil",
            css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
            tooltip: "Completat",
          },
        }[s] || s);

      const getButtons = (s) =>
        ({
          draft: [
            [
              [
                40,
                "Redenumește",
                ["fas fa-file-signature", "font-size:2.2rem;color:#39f;"],
              ],
              2,
              13,
            ],
          ],
          signature_waiting: [
            [
              [
                42,
                "Trimite spre semnare",
                ["fas fa-signal-stream", "font-size:2.2rem;color:#39f;"],
              ],
              14,
              13,
            ],
          ],
          completion: [
            [
              [
                40,
                "Redenumește",
                ["fas fa-file-signature", "font-size:2.2rem;color:#39f;"],
              ],
              2,
              13,
            ],
          ],
          completed: [
            [
              [
                40,
                "Redenumește",
                ["fas fa-file-signature", "font-size:2.2rem;color:#39f;"],
              ],
              2,
              13,
            ],
          ],
          signature_waiting: [
            [
              [
                42,
                "Trimite spre semnare",
                ["fas fa-signal-stream", "font-size:2.2rem;color:#39f;"],
              ],
              14,
              13,
            ],
          ],

          in_progress_signature: [[14]],
          rejected: [[2, 14, 13]],
          approved: [[2, 14, 13]],
          cancelled: [[14, 13]],
        }[s] || "");
      const isIncludedInPaap = row.paap.length ? true : false;
      const socialIcon = {
        true: {
          icon: "fas fa-circle",
          border: "left",
          css: "padding: 0.5rem;color: green;font-size: 2rem;",
          tooltip: "Social",
        },
        false: {
          icon: "fas fa-circle",
          border: "left",
          css: "padding: 0.5rem;color: #8a6d3b;font-size: 2rem;",
          tooltip: "Non-social",
        },
      }[row.need?.needIsSocial];
      const paapIcon = {
        true: {
          icon: "fas fa-file",
          border: "left",
          css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
          tooltip: "Participă în PAAP",
        },
        false: {
          icon: "far fa-file",
          border: "left",
          css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
          tooltip: "Nu Participă în PAAP",
        },
      }[isIncludedInPaap];

      let name = "-";
      let id = "-";
      let cpv = row.need?.product?.cpvCategory?.cpvCode || "-";
      let price = "-";

      if (row.createdByUser) {
        name = `${row.createdByUser.last_name || ""} ${row.createdByUser
          .first_name || ""}`;
        id = row.createdByUser.id || "-";
      }
      price = this.prepareLocaleNumber(row.sumnr);

      const preparation = [
        row.name || "-",
        row.nryear || "-",
        row.institution?.name || "-",
        row.department?.name || "-",
        name,
        this.changeRoleView(row.createdByUserRoleF?.role),
        id,
        cpv,
        price,
        //socialIcon,
        paapIcon,
        getStatusIcon(row.needReferateStatus),
      ];
      const prepareBtn = getButtons(row.needReferateStatus) || [];

      if (
        Number.isInteger(row.createdByUserRole) &&
        row.createdByUserRole === this.getUserRole(true)
      ) {
        const btnMap = [
          [
            ["draft", "signature_waiting", "completion", "completed"],
            /*  [
              30,
              'Anulează',
              ['fas fa-exclamation-circle', 'font-size:2.2rem;color:#ce302d;'],
            ], */
            [3, "Șterge"],
          ],
          [["cancelled"], [2]],
        ];
        const x = row.needReferateStatus;

        (Array.isArray(prepareBtn[0]) ? prepareBtn[0] : prepareBtn).unshift(
          ...btnMap
            .map((row) => {
              if (row[0].includes(x)) {
                return row[1];
              }
            })
            .filter((row) => row)
        );
        prepareBtn[0] = prepareBtn[0].filter((el) => el != 8 && el != 9);
      }

      if (
        this.getUserRole() != "supervizor_achizitii" &&
        this.getUserRole() != "functionar"
      ) {
        prepareBtn[0] = prepareBtn[0].filter(
          (el) => el != 8 && el != 9 && (!el[0] || el[0] != 42)
        );
      }

      if (this.getUserRole() == "functionar") {
        prepareBtn[0] = prepareBtn[0].filter((el) => el != 8 && el != 9);
      }

      if (
        (row.createdByUserRole != this.getUserRole(true) &&
          this.getUserRole() == "functionar") ||
        row.needReferateStatus == "approved"
      ) {
        prepareBtn[0] = prepareBtn[0].filter((el) => el != 2);
      }

      if (
        row.createdByUserRole != this.getUserRole(true) ||
        row.needReferateStatus == "approved"
      ) {
        prepareBtn[0] = prepareBtn[0].filter(
          (el) => (!el[0] || el[0] != 40) && (!el[0] || el[0] != 42)
        );
      }

      if (row.needReferateStatus == "in_progress_signature") {
        prepareBtn[0].push([
          5,
          "Deschide lucrarea",
          ["fas fa-book", "font-size:2.2rem;color:#39f;"],
        ]);
      }

      preparation.push(prepareBtn);

      for (let index of this.disabledCols) {
        preparation.splice(index - 1, 1);
      }
      return preparation;
    },
    startGenerate(id, data, versionData = null) {
      this.generateReferenceData = {
        id,
        data,
        versionData,
      };
    },
    toTable() {
      this.$router
        .push(this.getFirstPath() + this.getSecondPath())
        .catch(() => {});
    },
    syncReference(id) {
      if (!this.PERMISIONS.edit) return;

      id = id ?? +this.$route.params.id;
      this.setSafeLoad(12000);
      const err = (m) => {
        this.$toastr.e(m || "Nu s-a găsit referatul.");
        this.setLoad(false);
        this.toTable();
      };
      const prepare = (response) => {
        if (
          Array.isArray(response?.data?.result) &&
          this.isObject(response.data.result[0])
        ) {
          response = response.data.result[0];
          if (
            [
              "draft",
              "rejected",
              "cancelled",
              "completion",
              "completed",
            ].includes(response.needReferateStatus)
          ) {

            this.startGenerate(id, response);
          } else this.toTable();
        } else {
          err();
        }
        this.setLoad(false);
      };

      if (Number.isInteger(id)) {
        const url = `/${this.$route.params.navigation}/referate/${id}`;

        if (url !== this.$route.path) this.$router.push(url);
        REFERENCE_ONCE(id)
          .then(prepare)
          .catch(err);
      } else if (this.openingPanel) {
        this.$router.go(-1);
      }
    },
    syncReferenceVersion(referenceId, version) {
      referenceId = +referenceId;
      version = +version;

      if (!Number.isInteger(referenceId) || !Number.isInteger(version)) return;

      version--;

      const error = (msg, warn) => {
        this.$toastr[warn ? "w" : "e"](
          msg || "Versiunele referatului nu au fost primite.",
          warn ? "Atenție" : "Error"
        );
        this.goToHomePage();
        this.setLoad();
      };

      this.setSafeLoad();
      REFERENCE_VERSIONS(referenceId)
        .then((res) => {
          if (Array.isArray(res?.data?.result)) {
            const findVersion = res.data.result.find(
              (e) => version === e.versionNumber
            );
            if (findVersion) {
              if (this.isObject(findVersion.data)) {
                const findIndex = res.data.result.findIndex(
                  (e) => e.versionNumber === version
                );

                const prepare = this.combineObjects(
                  res.data.result.map((e) => {
                    const x = e.data;
                    if (x.status) {
                      x.needReferateStatus = x.status;
                      delete x.status;
                    }
                    return x;
                  })
                )[findIndex];

                this.setLoad();
                this.startGenerate(referenceId, prepare, findVersion);
              } else {
                error("Structura datelor istoriei stocate nu coincide.", true);
              }
            } else {
              error("Versiunea referatului nu a fost găsită.", true);
            }
          } else {
            error();
          }
        })
        .catch(error);
    },
    syncUrl(currentRoute) {
      this.generateReferenceData = false;
      const sync = () => {
        const x = this.$route.params["id"];
        if (x) {
          const y = this.$route.params["version"];
          if (y) {
            this.syncReferenceVersion(x, y);
          } else {
            this.syncReference();
          }
        } else {
          this.reloadPage();
          this.generateReferenceData = false;
        }
      };

      if (currentRoute) {
        window.requestAnimationFrame(() => {
          if (currentRoute.path === this.$route.path) {
            sync();
          }
        });
      } else sync();
    },
  },
  created() {
    if (!this.PERMISIONS.list) return;

    this.updateRoleStatus();
    this.syncUrl();
  },
  mounted() {
    this.setLoad(false);
  },
};
</script>
